import "./App.css";
import styled from "styled-components";

import { Logo } from "./components";

const CanvasWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: #efefef;
`;

function App() {
  return (
    <div className="App">
      <CanvasWrapper>
        <Logo />
      </CanvasWrapper>
    </div>
  );
}

export default App;
