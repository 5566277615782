import { Canvas, useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";

import Model from "../models/Un";

const deg2rad = (degrees) => degrees * (Math.PI / 180);

const Logo = () => {
  useThree(({ camera }) => {
    camera.rotation.set(deg2rad(30), deg2rad(30), 0);
  });

  return (
    <>
      <color attach="bacckground" args={["#EFEFEF"]} />
      <OrbitControls
        enableZoom={false}
        enablePan={false}
        rotateSpeed={1}
        autoRotate={true}
        autoRotateSpeed={10}
        minPolarAngle={Math.PI * 0.2}
        maxPolarAngle={Math.PI * 0.4}
      />
      <ambientLight intensity={0.5} />
      <directionalLight position={[-2, 5, 2]} intensity={1} />
      <Model />
    </>
  );
};

const LogoScene = () => (
  <Canvas>
    <Logo />
  </Canvas>
);

export default LogoScene;

